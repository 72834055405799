import {
  Divider,
  Flex,
  Grid,
  GridItem,
  Hide,
  Tag,
  Text,
} from '@chakra-ui/react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import {
  price,
  shouldDisplayPrice,
  size,
  status as statusFormatter,
} from '../../Services';

import {
  DocumentMagnifyingGlassIcon,
  FolderArrowDownIcon,
} from '@heroicons/react/24/outline';
import { shouldDisplayParking } from '../../Services/Entities/Plot';

export default function Item({
  plot,
  displaySettings,
  status,
  isDesktop,
  onPlotClick,
}) {
  return (
    <>
      <Grid
        templateColumns="repeat(12, 1fr)"
        gridAutoFlow={'row dense'}
        textAlign={'left'}
        py={2}
        columnGap={4}
        rowGap={[1, 1, 1, 0]}
        cursor={'pointer'}
        onClick={() => onPlotClick(plot)}
      >
        <GridItem alignSelf={'center'} colSpan={[12, 3, 2, 1]} rowStart={1}>
          <Hide below="sm">
            <Tag
              className="status"
              display="flex"
              textAlign="center"
              justifyContent="center"
              paddingY="1"
              width="100%"
              height="auto"
              borderRadius={'sm'}
              backgroundColor={status.color}
              fontSize={'xs'}
              lineHeight={0}
            >
              {statusFormatter(status.name)}
            </Tag>
          </Hide>
        </GridItem>

        {/* GeneralPlotInfoSection */}
        <GridItem
          colSpan={[12, 10, 6]}
          display="grid"
          justifyContent="start"
          rowStart={[2, 1]}
          justifyItems="flex-start"
          gap={1}
        >
          <Flex gap={2}>
            <Hide above="sm">
              <Tag
                className="status"
                display="flex"
                textAlign="center"
                justifyContent="center"
                paddingY="1"
                paddingX="2"
                width="auto"
                alignSelf="flex-start"
                height="auto"
                borderRadius={'sm'}
                backgroundColor={status.color}
                fontSize={'xs'}
                lineHeight={0}
              >
                {statusFormatter(status.name)}
              </Tag>
            </Hide>
            {displaySettings.number === '1' && (
              <>
                <Hide below="sm">
                  <Text
                    as="span"
                    className="number"
                    color="gray.800"
                    display="block"
                    fontSize={'md'}
                  >
                    {plot.number} - {plot.type}
                  </Text>
                </Hide>
                <Hide above="sm">
                  <Text
                    as="span"
                    className="number"
                    color="gray.800"
                    display="block"
                    fontSize={'sm'}
                  >
                    {plot.number} - {plot.type}
                  </Text>
                </Hide>
              </>
            )}
          </Flex>
        </GridItem>

        <GridItem
          colSpan={[3, 3, 2, 1]}
          rowStart={[3, 2]}
          fontSize={{ base: 'xs' }}
          color="gray.900"
        >
          {shouldDisplayPrice(plot, displaySettings) ? (
            <Text>{price(plot.price)}</Text>
          ) : (
            <Text></Text>
          )}
        </GridItem>

        {shouldDisplayPrice(plot, displaySettings) &&
        shouldDisplayParking(plot) ? (
          <GridItem
            colSpan={[9, 9, 4, 3]}
            rowStart={[3, 3, 2]}
            fontSize={{ base: 'xs' }}
            color="gray.900"
          >
            <Text
              as="span"
              color="default.txt"
              opacity="50%"
              display={'inline-block'}
            >
              {plot.parking_description}
            </Text>
          </GridItem>
        ) : (
          <></>
        )}

        <GridItem
          rowStart={[5, 2]}
          colSpan={[
            shouldDisplayPrice(plot, displaySettings) &&
            shouldDisplayParking(plot)
              ? 12
              : 12,
            shouldDisplayPrice(plot, displaySettings) &&
            shouldDisplayParking(plot)
              ? 12
              : 12,
            shouldDisplayPrice(plot, displaySettings) &&
            shouldDisplayParking(plot)
              ? 7
              : 10,
          ]}
          columnGap={8}
          display={'flex'}
          flexWrap={'wrap'}
          overflow={'clip'}
          height={'1.125rem'}
          justifyContent={['auto', 'flex-end']}
        >
          {displaySettings.living_surface && (
            <Flex
              gap={{ base: '1', md: '1.5', lg: '2' }}
              alignItems={'center'}
              width={{ base: 'auto', lg: '7rem' }}
            >
              <FontAwesomeIcon icon={regular('house')} color={'#2d2d2d54'} />

              <Text
                className="value"
                as="span"
                fontSize={{ base: 'xs' }}
                minWidth={'6em'}
              >
                {size(plot.living_surface)}
                <sup>2</sup>
                <Text
                  className="value"
                  as="span"
                  title="Woonoppervlakte"
                  display={isDesktop ? 'inline' : 'none'}
                  color="default.txt"
                  opacity="50%"
                >
                  {' '}
                  wonen
                </Text>
              </Text>
            </Flex>
          )}

          {displaySettings.plot_surface && (
            <Flex
              gap={{ base: '1', md: '1.5', lg: '2' }}
              alignItems={'center'}
              width={{ base: 'auto', lg: '7rem' }}
              minWidth={'6em'}
            >
              <FontAwesomeIcon icon={regular('house')} color={'#2d2d2d54'} />

              <Text className="value" as="span" fontSize={{ base: 'xs' }}>
                {size(plot.plot_surface)}
                <sup>2</sup>
                <Text
                  className="value"
                  as="span"
                  title="Kaveloppervlakte"
                  display={isDesktop ? 'inline' : 'none'}
                  color="default.txt"
                  opacity="50%"
                >
                  {' '}
                  kavel
                </Text>
              </Text>
            </Flex>
          )}

          {displaySettings.room_count && (
            <Flex
              gap={{ base: '1', md: '1.5', lg: '2' }}
              alignItems={'center'}
              width={{ base: 'auto', lg: '7rem' }}
              minWidth={{ md: '6em' }}
            >
              <FontAwesomeIcon icon={regular('bed')} color={'#2d2d2d54'} />
              <Text className="value" as="span" fontSize={{ base: 'xs' }}>
                {plot.room_count}
                <Text
                  className="value"
                  as="span"
                  title="Aantal kamers"
                  display={isDesktop ? 'inline' : 'none'}
                  color="default.txt"
                  opacity="50%"
                >
                  {' '}
                  kamers
                </Text>
              </Text>
            </Flex>
          )}
        </GridItem>

        <Hide below="sm">
          <GridItem
            rowStart={[5, 2]}
            colSpan={[3, 3, 3, 3, 2]}
            display={'flex'}
            flexWrap={'wrap'}
            overflow={'clip'}
            height={'1.125rem'}
            justifyContent={['flex-end']}
            gap={{ base: '1', md: '1.5', lg: '2' }}
          >
            {plot.downloads_page && (
              <Flex
                as="a"
                href={plot.downloads_page}
                target="_blank"
                title="Bekijk de downloads"
                alignItems={'center'}
                width={'1.5rem'}
                color="#2d2d2d"
                opacity={0.54}
                _hover={{ opacity: 0.75 }}
              >
                <FolderArrowDownIcon height={'1.25rem'} color="currentColor" />
              </Flex>
            )}

            {plot.detail_page && (
              <Flex
                as="a"
                href={plot.detail_page}
                target="_blank"
                title="Bekijk woningtype details"
                alignItems={'center'}
                width={'1.5rem'}
                color="#2d2d2d"
                opacity={0.54}
                _hover={{ opacity: 0.75 }}
              >
                <DocumentMagnifyingGlassIcon height={'1.25rem'} />
              </Flex>
            )}

            {plot.registration_page && (
              <Flex
                as="a"
                href={plot.registration_page}
                target="_blank"
                title="Inschrijven voor deze woning"
                alignItems={'center'}
                width={'1.5rem'}
                color="#2d2d2d"
                opacity={0.54}
                _hover={{ opacity: 0.75 }}
              >
                <FontAwesomeIcon icon={regular('file-signature')} />
              </Flex>
            )}
          </GridItem>
        </Hide>
      </Grid>
      <Divider orientation="horizontal" width="auto" />
    </>
  );
}

Item.propTypes = {
  plot: PropTypes.object.isRequired,
  displaySettings: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  onPlotClick: PropTypes.func.isRequired,
};
