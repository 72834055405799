import { defineStyle } from '@chakra-ui/react';

export const Solid = defineStyle(() => {
  return {
    bg: 'primary.bg.default',
    color: 'primary.txt',
    borderColor: 'primary.bg.transparent',
    borderRadius: 0,
    padding: '12px',
    fontSize: 'sm',

    _hover: {
      bg: 'primary.bg.darker',
    },

    _active: {
      bg: 'primary.bg.darkest',
    },

    _focus: {
      bg: 'primary.bg.darker',
    },
  };
});
