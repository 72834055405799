import {
  LOAD_PROJECTDATA_ERROR,
  LOAD_PROJECTDATA_LOADING,
  LOAD_PROJECTDATA_SUCCESS,
  LAYER_CHANGE,
  CLOSE_POPUP,
  SET_HOTSPOT,
} from './actions';
import {
  generateLayerSlug,
  // generatePlotSlug,
  getActiveEntityFromSlug,
  // removePlotSlugFromPath,
} from '../../Services/Urls/Url';

const initialState = {
  projectData: false,
  loading: false,
  error: '',
  theme: null,
  allLayers: [],
  activeLayer: null,
  isPopupOpen: false,
  activeHotspot: null,
};

/**
 * Recucer that handles the state of ProjectData. You can never return the Inital State, always return a modified copy.
 * @param {object} state Initial State
 * @param {object} action Action type that determines what will be done
 * @returns {object} The new modified state
 */
export default function reduxThunkReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PROJECTDATA_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case LOAD_PROJECTDATA_SUCCESS:
      return setProjectDataSuccessObject(state, action);

    case LOAD_PROJECTDATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case LAYER_CHANGE:
      window.history.pushState(
        {},
        '',
        generateLayerSlug(action.payload.activeLayer, state.projectData.layers)
      );

      return {
        ...state,
        activeLayer: action.payload.activeLayer,
      };

    case CLOSE_POPUP:
      // window.history.pushState(
      //   {},
      //   '',
      //   removePlotSlugFromPath(action.payload.shouldRemovePlotSlug)
      // );

      return {
        ...state,
        activeHotspot: null,
        isPopupOpen: false,
      };

    case SET_HOTSPOT:
      // action.payload.activeHotspot !== null &&
      //   window.history.pushState(
      //     {},
      //     '',
      //     generatePlotSlug(
      //       action.payload.layer,
      //       state.projectData.layers,
      //       action.payload.activeHotspot
      //     )
      //   );

      return {
        ...state,
        activeHotspot: action.payload.activeHotspot,
        isPopupOpen: true,
      };

    default:
      return state;
  }
}

/**
 * This method returns the manipulated project data for the success case.
 * @param {object} state Initial State from Redux
 * @param {object} action Action type that determines what will be done
 * @returns {object} The new modified state
 */
function setProjectDataSuccessObject(state, action) {
  const allLayers = action.projectData.layers;
  const activeEntity = getActiveEntityFromSlug(action.projectData.layers);
  const activeLayer = activeEntity.layer;
  const activeHotspot = activeEntity.hotspot;

  return {
    ...state,
    projectData: action.projectData,
    loading: false,
    theme: action.projectData.style_settings,
    activeLayer: activeLayer,
    activeHotspot: activeHotspot,
    allLayers: allLayers,
    isPopupOpen: activeEntity.hotspot !== null,
  };
}
