const preparePreload = () => {
  window.preloaded = {
    layers: {},
    plots: {},
  };
};
/**
 * Preload the background of all the child layers of layer.
 * @param {object} newActiveLayer the newly active layer
 * @param {Array} layers The layer we want to preload the children's backgrounds of.
 * @returns {void}
 */
const preloadLayerBackgrounds = (newActiveLayer, layers) => {
  if (layers === undefined) return;

  const result = layers
    .filter((layer) => layer.parent_id === newActiveLayer.id)
    .reduce((map, obj) => {
      map[obj.background.url] = generateImage(obj.background.url);
      return map;
    }, {});

  window.preloaded.layers = { ...window.preloaded.layers, ...result };
};

/**
 * Preload all the images belonging to plot hotspots of the chosen layer.
 * @param {object} layer the newly active layer
 * @param {Array} hotspots The layer we want to preload the plot hotspot images for.
 * @param {object} plots The project data plots
 * @returns {void}
 */
const preloadPlotImages = (layer, hotspots = [], plots = []) => {
  // Create a Set for quick lookups
  const plotHotspots = new Set(
    hotspots
      .filter(
        (hotspot) =>
          hotspot.layer_id === layer.id &&
          hotspot.entity_type === 'App\\Models\\Plot'
      )
      .map((hotspot) => hotspot.entity_id)
  );

  // Reduce the filtered plots to an image map directly
  const result = plots.reduce((map, plot) => {
    if (plot.media.length > 0 && plotHotspots.has(plot.id)) {
      plot.media.forEach((media) => {
        map[media.url] = generateImage(media.url);
      });
    }
    return map;
  }, {});

  // Merge results with existing preloaded data
  window.preloaded.plots = { ...window.preloaded.plots, ...result };
};

const generateImage = (url) => {
  const img = new Image();
  img.src = url;
  return img;
};

export { preparePreload, preloadLayerBackgrounds, preloadPlotImages };
