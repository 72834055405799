import { Divider, Flex, Grid, Tag, Text } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import {
  price,
  shouldDisplayPrice,
  size,
  status as statusFormatter,
} from '../../Services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function ItemMobile({
  plot,
  displaySettings,
  status,
  onPlotClick,
}) {
  return (
    <>
      <Grid
        gridTemplateColumns="3fr 1fr"
        gap={10}
        mb={2}
        mt={2}
        onClick={() => onPlotClick(plot)}
      >
        <Flex flexDir="column" gap={2}>
          <Text
            as="span"
            className="number"
            color="gray.800"
            display="block"
            fontSize={'sm'}
          >
            {plot.number} - {plot.type}
          </Text>

          <Grid gridTemplateColumns="1fr 1fr 1fr">
            <div>
              {shouldDisplayPrice(plot, displaySettings) ? (
                <Text fontSize="xs">{price(plot.price)}</Text>
              ) : (
                <Text></Text>
              )}
            </div>

            {displaySettings.living_surface && (
              <Flex gap={1} alignItems={'center'} justifyContent="flex-end">
                <FontAwesomeIcon icon={regular('house')} color={'#2d2d2d54'} />

                <Text className="value" as="span" fontSize={{ base: 'xs' }}>
                  {size(plot.living_surface)}
                  <sup>2</sup>
                </Text>
              </Flex>
            )}

            {displaySettings.room_count && (
              <Flex gap={1} alignItems={'center'} justifyContent="flex-end">
                <FontAwesomeIcon icon={regular('bed')} color={'#2d2d2d54'} />
                <Text className="value" as="span" fontSize={{ base: 'xs' }}>
                  {plot.room_count}
                </Text>
              </Flex>
            )}
          </Grid>
        </Flex>
        <Flex alignItems="center">
          <Tag
            className="status"
            display="inline-flex"
            textAlign="center"
            justifyContent="center"
            height="auto"
            borderRadius={'sm'}
            padding="0.4rem"
            backgroundColor={status.color}
            fontSize="0.6rem"
            lineHeight={0}
          >
            {statusFormatter(status.name)}
          </Tag>
        </Flex>
      </Grid>
      <Divider orientation="horizontal" width="auto" />
    </>
  );
}

ItemMobile.propTypes = {
  plot: PropTypes.object.isRequired,
  displaySettings: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  onPlotClick: PropTypes.func.isRequired,
};
