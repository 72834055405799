import { IconButton } from '@chakra-ui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import PropTypes from 'prop-types';
import React from 'react';

export default function Reset({ resetCallback, filtersChanged }) {
  return (
    <IconButton
      aria-label="Reset filter"
      size="xs"
      variant="link"
      onClick={resetCallback}
      icon={<XMarkIcon width={16} height={16} />}
      title="Reset filter"
      display={filtersChanged() === true ? 'block' : 'none'}
    >
      Reset
    </IconButton>
  );
}

Reset.propTypes = {
  resetCallback: PropTypes.func.isRequired,
  filtersChanged: PropTypes.func.isRequired,
};
