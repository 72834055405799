import { defineStyle } from '@chakra-ui/react';

export const Default = defineStyle(() => {
  return {
    variant: 'solid',
    fontSize: 'sm',
    fontWeight: 400,
    minW: 0,
    border: '1px solid',
    borderColor: 'primary.border.default',
    borderRadius: '2px',
    transition:
      'color 0.15s ease-out, background 0.15s ease-out, border 0.15s ease-out',
    size: 'lg',

    _hover: {
      color: 'primary.txt',
    },

    _active: {
      color: 'primary.txt',
    },

    _focus: {
      color: 'primary.txt',
    },
  };
});
