import { Box, Select, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export default function OrderMenu({ onSortChange, sort }) {
  const onValueChange = (e) => {
    onSortChange(e.target.value);
  };

  return (
    <Box
      width={'full'}
      defaultValue={sort}
      display={'flex'}
      alignItems={'center'}
      columnGap={6}
    >
      <Text as="label" htmlFor="list-sort" userSelect={'none'} flexShrink={0}>
        Sorteren op{' '}
      </Text>
      <Select id="list-sort" onChange={onValueChange}>
        <option value="number">Bouwnummer ↑</option>
        <option value="costDESC">Prijs ↓</option>
        <option value="costASC">Prijs ↑</option>
        <option value="type">Woningtype</option>
        <option value="livingSurfaceDESC">Woonoppervlakte ↓</option>
        <option value="livingSurfaceASC">Woonoppervlakte ↑</option>
        <option value="plotSurfaceDESC">Kaveloppervlakte ↓</option>
        <option value="plotSurfaceASC">Kaveloppervlakte ↑</option>
        <option value="status">Status</option>
      </Select>
    </Box>
  );
}

OrderMenu.propTypes = {
  sort: PropTypes.string.isRequired,
  onSortChange: PropTypes.func.isRequired,
};
