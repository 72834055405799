import {
  Box,
  FormLabel,
  Input,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { price, size } from '../../Services/Formatters/Number';
import Base from './Base';
import FilterHeader from './FilterHeader';

export default class RangeFilter extends Base {
  handleChange = (value) => {
    const valuesStructure = {
      min: value[0] ?? this.props.values.min,
      max: value[1] ?? this.props.values.max,
      stepSize: this.props.values.stepSize,
      steps: this.props.values.steps,
    };

    this.props.onChange(this.props.slug, valuesStructure);
  };

  isPriceFilter = () => {
    return this.props.slug === 'price';
  };

  formatValueLabel = (number) => {
    if (this.isPriceFilter()) {
      return price(number);
    }

    return (
      <span>
        {size(number)}
        <sup>2</sup>
      </span>
    );
  };

  render() {
    return (
      <>
        {this.props.label && (
          <FilterHeader
            label={this.props.label}
            resetCallback={this.resetCallback}
            filtersHaveChanged={this.filtersHaveChanged}
            showResetButton={true}
          />
        )}

        <Box
          fontSize="sm"
          display={'flex'}
          alignItems={'center'}
          flexWrap={'wrap'}
        >
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <FormLabel
              display={'flex'}
              gap={2}
              alignItems={'center'}
              margin={0}
            >
              <span>Van</span>
              <NumericFormat
                name="min"
                defaultValue={this.props.selected.min}
                value={this.props.selected.min}
                onValueChange={(values) => {
                  this.handleChange([
                    values.floatValue,
                    this.props.selected.max,
                  ]);
                }}
                allowNegative={false}
                thousandSeparator="."
                decimalSeparator=","
                prefix={this.isPriceFilter() ? '€' : ''}
                suffix={!this.isPriceFilter() ? ' m²' : ''}
                customInput={Input}
                fontWeight={'bold'}
                width={'full'}
                minW={{ lg: '52' }}
              />
            </FormLabel>

            <FormLabel
              display={'flex'}
              gap={2}
              alignItems={'center'}
              margin={0}
            >
              <span> tot </span>

              <NumericFormat
                name="max"
                defaultValue={this.props.selected.max}
                value={this.props.selected.max}
                onValueChange={(values) => {
                  this.handleChange([
                    this.props.selected.min,
                    values.floatValue,
                  ]);
                }}
                allowNegative={false}
                thousandSeparator="."
                decimalSeparator=","
                prefix={this.isPriceFilter() ? '€' : ''}
                suffix={!this.isPriceFilter() ? ' m²' : ''}
                customInput={Input}
                fontWeight={'bold'}
                width={'full'}
                minW={{ lg: '52' }}
              />
            </FormLabel>
          </Box>

          <Box
            px={3}
            width={'full'}
            flexBasis={'100%'}
            flexShrink={0}
            flexGrow={1}
          >
            {!this.props.hideSlider && (
              <RangeSlider
                mt={4}
                aria-label={['min', 'max']}
                value={[this.props.selected.min, this.props.selected.max]}
                min={this.props.default.min}
                max={this.props.default.max}
                step={this.props.default.stepSize}
                minStepsBetweenThumbs={1}
                onChange={this.handleChange}
              >
                <RangeSliderTrack h={'2px'}>
                  <RangeSliderFilledTrack bg="#000" />
                </RangeSliderTrack>

                <Tooltip
                  hasArrow
                  placement="top"
                  label={this.formatValueLabel(this.props.selected.min)}
                >
                  <RangeSliderThumb
                    index={0}
                    bg={'#FFF'}
                    w={'1.375rem'}
                    h={'1.375rem'}
                    boxShadow="0 2px 4px rgb(0 0 0 / 30%)"
                  />
                </Tooltip>
                <Tooltip
                  hasArrow
                  placement="top"
                  label={this.formatValueLabel(this.props.selected.max)}
                >
                  <RangeSliderThumb
                    index={1}
                    bg={'#FFF'}
                    w={'1.375rem'}
                    h={'1.375rem'}
                    boxShadow="0 2px 4px rgb(0 0 0 / 30%)"
                  />
                </Tooltip>
              </RangeSlider>
            )}
          </Box>
        </Box>
      </>
    );
  }
}
