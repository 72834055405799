import { Radio, RadioGroup } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import Base from './Base';
import FilterHeader from './FilterHeader';

export default class RadioFilter extends Base {
  static propTypes = {
    values: PropTypes.array.isRequired,
    default: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
  };

  handleChange = (event) => {
    this.props.onChange(this.props.slug, event);
  };

  render() {
    return (
      <>
        <FilterHeader
          label={this.props.label}
          resetCallback={this.resetCallback}
          filtersHaveChanged={this.filtersHaveChanged}
          showResetButton={true}
        />

        <RadioGroup
          name={this.props.slug}
          defaultValue={[this.props.default]}
          value={this.props.selected}
          onChange={this.handleChange}
        >
          {this.props.values.map((value) => {
            return (
              <Radio key={value} value={value} w="100%">
                {value}
              </Radio>
            );
          })}
        </RadioGroup>
      </>
    );
  }
}
