import {
  Box,
  Button,
  Heading,
  Link,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverContent,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  AdjustmentsHorizontalIcon,
  ChevronLeftIcon,
  MapIcon,
} from '@heroicons/react/16/solid';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import DropdownIcon from '../../Assets/DropdownIcon';
import ListViewIcon from '../../Assets/ListViewIcon';
import { getSiblings } from '../../Services/Entities/Layer';
import { useClickAway } from '../../Services/Hooks/useClickAway';

export default function MapControls({
  children,
  activeLayer,
  changeActiveLayer,
  onToggleMap,
  toggleFilterModal,
  isFilterOpen,
  layers,
  initialLayers,
  isListOpen,
  onListOpen,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const parent = layers[activeLayer.parent_id];
  const siblings = getSiblings(layers, initialLayers, activeLayer);

  const onBackButtonClick = () => {
    setIsOpen(false);
    changeActiveLayer(parent);
  };

  const onLayerLinkClick = (layer) => {
    setIsOpen(false);
    changeActiveLayer(layer);
  };

  const isDesktop = useMediaQuery('(min-width: 768px)');
  const hasSiblings = siblings.length > 0;
  const popoverRef = useRef(null);

  useClickAway(popoverRef, () => {
    setIsOpen(false);
  });

  return (
    <Popover
      position="relative"
      zIndex="10"
      arrowSize="17"
      strategy="absolute"
      placement="top-end"
      isOpen={isOpen}
      matchWidth={true}
      offset={[0, 0]}
      closeOnBlur={true}
    >
      <PopoverContent
        borderRadius="0"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
        width="full"
        maxHeight="76vh"
        overflowY="scroll"
        py={9}
        px={14}
        zIndex={100}
        ref={popoverRef}
      >
        <Text
          as={'span'}
          letterSpacing={'7px'}
          fontSize={'sm'}
          fontWeight={'bold'}
          textTransform={'uppercase'}
          mb={1}
        >
          Ga naar
        </Text>
        <Box pt={4} display={'flex'} flexWrap={'wrap'} fontSize={'sm'}>
          {siblings.map((layer) => (
            <Link
              key={layer.id}
              onClick={() => onLayerLinkClick(layer)}
              w={'full'}
              lineHeight={'1.5rem'}
              py={2}
              textAlign={'left'}
              justifyContent={'start'}
              rounded={'none'}
              bg={'transparent'}
              fontWeight={layer.id === activeLayer.id ? '600' : '300'}
              _hover={{
                fontWeight: layer.id === activeLayer.id ? '600' : '400',
              }}
              _focus={{
                fontWeight: layer.id === activeLayer.id ? '600' : '400',
              }}
              _active={{
                fontWeight: layer.id === activeLayer.id ? '600' : '400',
              }}
            >
              {layer.name}
            </Link>
          ))}
        </Box>
        <PopoverArrow />
      </PopoverContent>

      <Box
        position="fixed"
        top={0}
        left={0}
        zIndex="10"
        display="flex"
        w="full"
        background={'white'}
        h={16}
      >
        <PopoverAnchor>
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex="-1"
          ></Box>
        </PopoverAnchor>

        {!isListOpen && (
          <Box display={'flex'}>
            {parent !== undefined && (
              <Button
                variant={'outline'}
                onClick={onBackButtonClick}
                borderRadius={0}
                borderColor={'transparent'}
                borderRightColor={'primary.border.default'}
                fontWeight={'bold'}
                mr={0}
                ml={'auto'}
                flex={'0 0 auto'}
                gap={1}
              >
                <Box w={4} h={4}>
                  <ChevronLeftIcon width={16} height={16} />
                </Box>

                <Heading as="span" fontSize={'base'}>
                  {isDesktop[0] && 'Terug'}
                </Heading>
              </Button>
            )}
            <Button
              variant={'outline'}
              ml={0}
              mr={'auto'}
              flex={isDesktop[0] ? '0 0 auto' : '1 0 auto'}
              display="flex"
              justifyContent="start"
              alignItems={'center'}
              pos={'relative'}
              px={4}
              onClick={() => hasSiblings && setIsOpen(!isOpen)}
              isActive={isOpen}
              borderWidth={0}
              gap={4}
              cursor={hasSiblings ? 'pointer' : 'default'}
            >
              <Heading
                as="span"
                fontWeight={400}
                fontSize={'lg'}
                minW={'fit-content'}
              >
                {activeLayer.name}
              </Heading>

              {hasSiblings && (
                <Box mr={0} ml={'auto'}>
                  <DropdownIcon />
                </Box>
              )}
            </Button>
          </Box>
        )}

        {children}

        <Box display={'flex'} flex={'0 0 auto'} ml={'auto'} mr={0}>
          <Button
            variant={'outline'}
            onClick={toggleFilterModal}
            isActive={isFilterOpen}
            borderRadius={0}
            borderColor={'transparent'}
            borderLeftColor={'primary.border.default'}
            color={'default.txt'}
            mr={0}
            ml={'auto'}
            minW={14}
            h={'auto'}
            gap={2}
            px={5}
            flex={'0 0 auto'}
          >
            {isDesktop[0] && (
              <Heading as="span" fontSize={'base'}>
                Filters
              </Heading>
            )}

            <Box>
              <AdjustmentsHorizontalIcon width={16} height={16} />
            </Box>
          </Button>

          <Button
            variant={'outline'}
            onClick={() => (isDesktop[0] ? onListOpen() : onToggleMap())}
            borderRadius={0}
            borderColor={'transparent'}
            borderLeftColor={'primary.border.default'}
            color={'default.txt'}
            mr={0}
            ml={'auto'}
            px={5}
            minW={14}
            h={'auto'}
            gap={2}
            flex={'0 0 auto'}
          >
            {isDesktop[0] && (
              <Heading as="span" fontSize={'base'}>
                {isListOpen && 'Kaart'}
                {!isListOpen && 'Lijst'}
              </Heading>
            )}

            <Box fill={'primary.bg.default'}>
              {isListOpen && <MapIcon width={16} height={16} />}
              {!isListOpen && <ListViewIcon width={16} height={16} />}
            </Box>
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

MapControls.propTypes = {
  children: PropTypes.node,
  activeLayer: PropTypes.object.isRequired,
  changeActiveLayer: PropTypes.func.isRequired,
  onToggleMap: PropTypes.func,
  toggleFilterModal: PropTypes.func.isRequired,
  onListOpen: PropTypes.func,
  isFilterOpen: PropTypes.bool.isRequired,
  isListOpen: PropTypes.bool,
  layers: PropTypes.object.isRequired,
  initialLayers: PropTypes.array.isRequired,
};
