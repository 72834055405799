import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import reduxThunkReducer from './reducer';

/**
 * Function that will create the store object which will be passed down by the provider
 * @returns {object} Store object
 */
export function configureStore() {
  const middleware = [thunk];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    reduxThunkReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );
}
