/**
 * Recursively generates an array of all layers in the project
 * @param {object} parent The parent layer to get the descendents of
 * @param {Array} allLayers All layers in the project
 * @returns {Array} Array of all the layers in the project
 */
const getDescendantsRecursively = (parent, allLayers) => {
  const children = Object.values(allLayers).filter(
    (item) => parent.id === item.parent_id
  );

  if (children.length === 0) {
    return [];
  }

  return children.concat(
    children
      .map((child) => getDescendantsRecursively(child, allLayers))
      .filter((child) => child.length > 0)
  );
};

/**
 * Filters all layers to return a Layer with a specific ID
 * @param {Array} layers An array of layers
 * @param {number} id The ID of the layer we want to get
 * @returns {object} the Layer with a matching ID
 */
const findLayerById = (layers, id) => {
  return layers[id];
};

/**
 * Returns all the parent layers of `layer` by recursively matching the parent_id property, result includes `layer` itself
 * @param {object} layer Layer we want to get the parents of
 * @param {Array} layers Array of all layers that we are searching in
 * @returns {Array} An array of all the parents of `layer` including `layer` itself
 */
const getParents = (layer, layers) => {
  return !layer.parent_id
    ? [layer]
    : [layer].concat(
        getParents(findLayerById(layers, layer.parent_id), layers)
      );
};

const getSiblings = (layers, initialLayers, current) => {
  if (current.parent_id === null) return [];

  const parent = layers[current.parent_id];
  if (parent === undefined) return [];

  return initialLayers.filter((layer) => layer.parent_id === parent.id);
};

/**
 * @param {object} hotspot The hotspot we want to get the color for
 * @param {Array} layers All layers in the project
 * @param {Array} plots All plots in the project
 * @param {Array} hotspots All hotspots in the project
 * @returns {array} An array of all unique statuses of the plots on the layer and its descendants
 */
const getLayerHotspotColor = (layer, layers, plots, hotspots) => {
  const descendants = [
    // We need to add the current layer to the list as well,
    // or we won't include plots on the layer linked to the current hotspot
    layer,
    ...getDescendantsRecursively(layer, layers),
  ]
    .map((item) => item.id)
    .filter((id) => id !== undefined);

  const plotIds = hotspots
    // All plot hotspots
    .filter((item) => item.entity_type === 'App\\Models\\Plot')
    // On any of the decendant layers
    .filter((item) => descendants.includes(item.layer_id))
    // Grab only the plot id
    .map((item) => item.entity_id);

  const plotStatuses = plots
    // Only the plots on descendant layers
    .filter((item) => plotIds.includes(item.id))
    // Grab only the status
    .map((item) => item.status);

  // Remove duplicate statuses
  return [...new Set(plotStatuses)];
};

export {
  findLayerById,
  getDescendantsRecursively,
  getLayerHotspotColor,
  getParents,
  getSiblings,
};
