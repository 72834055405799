import React from 'react';
import ReactDOM from 'react-dom';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from './Redux/ApiCall/configureStore';
import Project from './Templates/Project';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_AXE === true) {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

createRoot(document.getElementById('app')).render(
  <Provider store={configureStore()}>
    <Project />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
