import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  CheckboxFilter,
  RadioFilter,
  RangeFilter,
  StatusFilter,
} from '../Molecules/index';
import { slugify } from '../Services';

export default function FilterContainer({
  filters,
  filterCallback,
  filterDisplay,
  toggleFilterModal,
  resetFilters,
  filteredPlots,
  activeFilterValues,
  isFiltersInitiallyOpen,
}) {
  const filterTypes = {
    checkbox: CheckboxFilter,
    radio: RadioFilter,
    status: StatusFilter,
    range: RangeFilter,
  };

  const filteredPlotsNumber = filteredPlots.length;

  useEffect(() => {
    if (isFiltersInitiallyOpen) toggleFilterModal();
  }, []);

  return (
    <Modal
      isOpen={filterDisplay}
      onClose={() => toggleFilterModal()}
      size={'xl'}
      zIndex={100}
    >
      <ModalOverlay />
      <ModalContent>
        <Box
          position={'sticky'}
          top={0}
          background={'white'}
          width={'full'}
          zIndex={100}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          px={6}
          py={4}
        >
          <ModalHeader flex={'0 0 auto'} p={0}>
            Filter instellingen
          </ModalHeader>

          <ModalCloseButton
            variant={'solid'}
            backgroundColor={'primary.bg.default'}
            borderRadius={'full'}
            flex={'0 0 auto'}
            p={5}
            color={'primary.txt'}
            transition="color 0.15s ease-out, background 0.15s ease-out, border 0.15s ease-out"
            position={'static'}
            _hover={{
              bg: 'primary.bg.darker',
            }}
            _active={{
              bg: 'primary.bg.darkest',
            }}
            _focus={{
              bg: 'primary.bg.darker',
            }}
          />
        </Box>

        <ModalBody>
          <Flex pb="5" as="section" justifyContent={'space-between'}>
            <Center as="h2">
              <Text fontSize="2xl" mr="3" as="span">
                {filteredPlotsNumber}
              </Text>
              <Text as="span">
                {filteredPlotsNumber === 1 ? 'Resultaat' : 'Resultaten'}
              </Text>
            </Center>

            <Button
              variant="link"
              textDecoration="underline"
              fontWeight="normal"
              fontSize={'14px'}
              onClick={resetFilters}
            >
              Reset filters
            </Button>
          </Flex>

          {filters
            .sort((a, b) => a.order - b.order)
            .map((filter) => {
              if (filter.type === null) return;

              const FilterType = filterTypes[filter.type.type];

              return (
                <Stack direction="column" pb={5} key={filter.slug} as="section">
                  <FilterType
                    label={filter.label}
                    slug={slugify(filter.slug)}
                    values={filter.values}
                    default={filter.default}
                    onChange={filterCallback}
                    filteredPlots={filteredPlots}
                    selected={activeFilterValues[slugify(filter.slug)]}
                  />
                </Stack>
              );
            })}
        </ModalBody>

        <ModalFooter
          display={'flex'}
          gap={4}
          position={'sticky'}
          bottom={0}
          background={'white'}
          width={'full'}
          zIndex={100}
        >
          <Button variant={'solid'} onClick={() => toggleFilterModal()}>
            Opslaan
          </Button>

          <Button variant={'outline'} onClick={() => resetFilters()}>
            Reset
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

FilterContainer.propTypes = {
  filters: PropTypes.array.isRequired,
  filterCallback: PropTypes.func.isRequired,
  filterDisplay: PropTypes.bool.isRequired,
  filteredPlots: PropTypes.array.isRequired,
  toggleFilterModal: PropTypes.func.isRequired,
  activeFilterValues: PropTypes.object.isRequired,
  resetFilters: PropTypes.func.isRequired,
  isFiltersInitiallyOpen: PropTypes.bool.isRequired,
};
