import Api from './api';

export const LOAD_PROJECTDATA_LOADING = 'REDUX_THUNK_LOAD_PROJECTDATA_LOADING';
export const LOAD_PROJECTDATA_SUCCESS = 'REDUX_THUNK_LOAD_PROJECTDATA_SUCCESS';
export const LOAD_PROJECTDATA_ERROR = 'REDUX_THUNK_LOAD_PROJECTDATA_ERROR';
export const LAYER_CHANGE = 'CHANGE_LAYER';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SET_HOTSPOT = 'SET_HOTSPOT';

export const loadProjectData = () => (dispatch) => {
  dispatch({ type: LOAD_PROJECTDATA_LOADING });
  Api.getProjectData()
    .then((response) => response.json())
    .then(
      (projectData) =>
        dispatch({ type: LOAD_PROJECTDATA_SUCCESS, projectData }),
      (error) =>
        dispatch({
          type: LOAD_PROJECTDATA_ERROR,
          error: error.message || 'Unexpected Error!!!',
        })
    );
};

export const changeActiveLayer = (layer) => ({
  type: LAYER_CHANGE,
  payload: {
    activeLayer: layer,
  },
});

export const closePopup = (shouldRemovePlotSlug = true) => ({
  type: CLOSE_POPUP,
  payload: {
    shouldRemovePlotSlug,
  },
});

export const setActiveHotspot = (layer, hotspot) => ({
  type: SET_HOTSPOT,
  payload: {
    activeHotspot: hotspot,
    layer: layer,
  },
});
