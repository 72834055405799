/**
 * Turns a number into a price based on international formatting standards.
 * @param {number} number A number representing a value in currency.
 * @returns {string} The formatted value including the currency.
 */
function price(number) {
  return Intl.NumberFormat('nl', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  }).format(number);
}

/**
 * Turns a number into a size based on international formatting standards.
 * @param {number} number A number representing a size in square meters.
 * @returns {string} The formatted value including the unit.
 */
function size(number) {
  return Intl.NumberFormat('nl', {
    style: 'unit',
    unit: 'meter',
    minimumFractionDigits: 0,
  }).format(number);
}

export { price, size };
