import PropTypes from 'prop-types';

function shouldDisplayPrice(plot, displaySettings) {
  return (
    displaySettings.price === '1' &&
    plot.status !== 'verkocht' &&
    plot.status !== 'voorbereiden-start-verkoop'
  );
}
function shouldDisplayParking(plot) {
  return (
    plot.parking_count && plot.parking_description && plot.parking_count > 0
  );
}

shouldDisplayPrice.propTypes = {
  plot: PropTypes.object.isRequired,
  displaySettings: PropTypes.object.isRequired,
};

shouldDisplayParking.propTypes = {
  plot: PropTypes.object.isRequired,
  displaySettings: PropTypes.object.isRequired,
};

export { shouldDisplayParking, shouldDisplayPrice };
