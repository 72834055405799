import PropTypes from 'prop-types';

function status(status) {
  return status.replace('-', ' ');
}

status.PropTypes = {
  status: PropTypes.string.isRequired,
};

export { status };
