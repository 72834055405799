import { Accordion, Box, Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { groupFilteredPlots, slugify } from '../../Services';
import Base from './Base';
import './checkboxFilter.css';
import FilterHeader from './FilterHeader';

export default class CheckboxFilter extends Base {
  static propTypes = {
    values: PropTypes.array.isRequired,
    default: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    filteredPlots: PropTypes.array,
  };

  state = {
    isActiveShowMore: false,
  };

  handleChange = (selected) => {
    this.props.onChange(this.props.slug, selected);
  };

  /**
   * Maps over the Woningtypes & generates checkbox filters.
   * Each checkbox filter displays the amount of available plots that match the filter.
   */
  renderCheckbox = () => {
    const houseTypeArray = groupFilteredPlots(this.props.filteredPlots, 'type');

    return this.props.values.map((value) => {
      const houseTypeResultsAmount =
        (houseTypeArray[value] && houseTypeArray[value].length) || 0;

      return (
        <Checkbox
          key={slugify(value)}
          value={value}
          disabled={
            this.props.selected.includes(value) && houseTypeResultsAmount === 0
          }
        >
          {value} ({houseTypeResultsAmount})
        </Checkbox>
      );
    });
  };

  render() {
    const checkboxes = this.renderCheckbox();

    return (
      <>
        <FilterHeader
          label={this.props.label}
          resetCallback={this.resetCallback}
          filtersHaveChanged={this.filtersHaveChanged}
          showResetButton={true}
        />

        {checkboxes.length > 0 ? (
          <CheckboxGroup
            name={this.props.slug}
            defaultValue={this.props.default}
            value={this.props.selected}
            onChange={this.handleChange}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              {checkboxes.slice(0, 5)}
            </Box>

            {checkboxes.length > 5 && (
              <>
                <Accordion
                  hidden={!this.state.isActiveShowMore}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  {checkboxes.slice(5, checkboxes.length)}
                </Accordion>

                <button
                  className="accordion-button"
                  onClick={() =>
                    this.setState({
                      isActiveShowMore: !this.state.isActiveShowMore,
                    })
                  }
                >
                  <span>
                    {this.state.isActiveShowMore ? 'Toon minder' : `Toon meer`}
                  </span>
                  {this.state.isActiveShowMore ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </button>
              </>
            )}
          </CheckboxGroup>
        ) : (
          'Geen beschikbare woningtypes'
        )}
      </>
    );
  }
}
