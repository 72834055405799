/**
 * Generates a rgba color from a hex color with an alpha value
 * @param {string} hex Hexadecimal color value
 * @param {string} alpha Alpha value
 * @returns {string} rgba color value
 */
const hexToRGB = (hex, alpha) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

/**
 * Lightens or darken a hex color.
 * https://stackoverflow.com/a/62515947/6903994
 * @param {string} color The hex color to be modified
 * @param {number} amount The amount we want to modify it by. Negative values darken the color, positive values lighten it.
 * @returns {string} The modified color
 */
const lightenDarkenColor = (color, amount) => {
  // #FFF not supportet rather use #FFFFFF
  const clamp = (val) => Math.min(Math.max(val, 0), 0xff);
  const fill = (str) => ('00' + str).slice(-2);

  const num = parseInt(color.substr(1), 16);
  const red = clamp((num >> 16) + amount);
  const green = clamp(((num >> 8) & 0x00ff) + amount);
  const blue = clamp((num & 0x0000ff) + amount);
  return (
    '#' +
    fill(red.toString(16)) +
    fill(green.toString(16)) +
    fill(blue.toString(16))
  );
};

export { hexToRGB, lightenDarkenColor };
