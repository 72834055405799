import { LatLngBounds } from 'leaflet';
const matches = (filteredPlots, plot) => {
  return (
    Array.isArray(filteredPlots) &&
    filteredPlots.some((filteredPlot) => filteredPlot.id === plot.id)
  );
};

const fill = (settings, status, matchesFilters, active) => {
  let fillOpacity = settings.svg_opacity_no_filter;
  if (active) {
    fillOpacity = settings.svg_opacity_hover_active;
  } else if (matchesFilters) {
    fillOpacity = settings.svg_opacity_filter;
  }

  return {
    fill: true,
    fillColor: status.color ?? '#fff',
    fillOpacity: fillOpacity,
    color: status.color ?? '#fff',
  };
};

const parseHotspotOpacitySettings = (activeLayer) => {
  return {
    svg_opacity_filter: parseFloat(activeLayer.svg_opacity_filter),
    svg_opacity_hover_active: parseFloat(activeLayer.svg_opacity_hover_active),
    svg_opacity_no_filter: parseFloat(activeLayer.svg_opacity_no_filter),
  };
};

const getCoordinates = (hotspot) => {
  const coordinates = hotspot.svg
    .split(',')
    .map((val, index, arr) => [arr[index], arr[index + 1]])
    .filter((val, index) => index % 2 === 0);

  try {
    return new LatLngBounds(coordinates).isValid() && coordinates;
  } catch (e) {
    return [];
  }
};

export { fill, getCoordinates, matches, parseHotspotOpacitySettings };
