import {
  Box,
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  ArrowsPointingOutIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import React from 'react';
import { MapControls, Popup, RangeFilter, StatusFilter } from '../Molecules';
import PlotModal from '../Molecules/Popup/PlotModal';
import { slugify } from '../Services';
import PlotMap from './PlotMap';
import { FilterContainer } from './index';

export default function Mobile({
  children,
  layers,
  initialLayers,
  plots,
  hotspots,
  filters,
  statuses,
  statusFilter,
  priceFilter,
  onFilterChange,
  onResetFilters,
  onLayerChange,
  onToggleFilterModal,
  onToggleMap,
  onClosePopup,
  onHotspotClick,
  activeLayer,
  activePlot,
  activeFilters,
  filteredPlots,
  showFilters,
  showMap,
}) {
  return (
    <Box px={6}>
      <Heading
        lineHeight={1.5}
        my={6}
        color={'primary.bg.default'}
        fontWeight={400}
        fontSize={'4xl'}
      >
        Filters
      </Heading>

      <Box
        display={'flex'}
        justifyContent={'stretch'}
        flexWrap={'wrap'}
        gap={8}
        mb={2}
      >
        <Box width={'full'}>
          <StatusFilter
            slug={slugify(statusFilter.slug)}
            values={statusFilter.values}
            default={statusFilter.default}
            onChange={onFilterChange}
            filteredPlots={filteredPlots}
            selected={activeFilters[slugify(statusFilter.slug)]}
          />
        </Box>

        <RangeFilter
          label={priceFilter.label}
          slug={slugify(priceFilter.slug)}
          values={priceFilter.values}
          default={priceFilter.default}
          onChange={onFilterChange}
          filteredPlots={filteredPlots}
          selected={activeFilters[slugify(priceFilter.slug)]}
        />

        <Button
          variant="outline"
          onClick={onToggleFilterModal}
          isActive={showFilters}
          rightIcon={<ChevronDownIcon width={16} height={16} />}
          width={'full'}
          borderRadius={'2px'}
        >
          Meer filters
        </Button>

        <Text
          as="p"
          textAlign={'center'}
          fontSize={'small'}
          p={2}
          bg={'#F9F9F9'}
          mt={-6}
          width={'full'}
        >
          <Text as="span" color={'primary.bg.default'}>
            <Text as="span" fontWeight={'bold'}>
              {filteredPlots.length ?? 0}
            </Text>{' '}
            {filteredPlots.length === 1 ? 'woning' : 'woningen'} gevonden
          </Text>
        </Text>
      </Box>

      <FilterContainer
        plots={plots}
        filters={filters}
        filterCallback={onFilterChange}
        filterDisplay={showFilters}
        filteredPlots={filteredPlots}
        toggleFilterModal={onToggleFilterModal}
        resetFilters={onResetFilters}
        activeFilterValues={activeFilters}
        isFiltersInitiallyOpen={false}
      />

      <Box
        pos={'relative'}
        display={'grid'}
        placeItems={'center'}
        mx={'-6'}
        mt={8}
      >
        <Image
          src={initialLayers[0].background.url}
          alt="Placeholder"
          w={'100%'}
          h={'auto'}
          mt={0}
          gridRow={1}
          gridColumn={1}
          zIndex={1}
          fetchPriority="high"
          loading="eager"
        />
        <Button
          variant="solid"
          boxSizing="content-box"
          fill={'primary.txt'}
          rounded={'full'}
          p={2.5}
          w={6}
          h={6}
          gridRow={1}
          gridColumn={1}
          zIndex={10}
          onClick={onToggleMap}
          boxShadow={'10xl'}
        >
          <Box
            width={'100%'}
            height={'100%'}
            position={'absolute'}
            inset={'0'}
            zIndex={'-1'}
            animation={'3s ease-out pulse infinite'}
            background={'primary.bg.default'}
            borderRadius={'999px'}
          />

          <ArrowsPointingOutIcon width={24} height={24} />
        </Button>
      </Box>

      <Modal
        isOpen={showMap}
        onClose={onToggleMap}
        size={'full'}
        zIndex={10}
        blockScrollOnMount={!showFilters && !activePlot}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={0}>
            <MapControls
              activeLayer={activeLayer}
              layers={layers}
              initialLayers={initialLayers}
              changeActiveLayer={onLayerChange}
              onToggleMap={onToggleMap}
              toggleFilterModal={onToggleFilterModal}
              isFilterOpen={showFilters}
            />
          </ModalHeader>

          <ModalBody p={0}>
            <PlotMap
              layers={layers}
              statuses={statuses}
              plots={plots}
              hotspots={hotspots}
              filteredPlots={filteredPlots}
              activeLayer={activeLayer}
              activeHotspot={activePlot}
              onHotspotClick={onHotspotClick}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {children}

      {activePlot !== null && showMap && (
        <PlotModal toggleOpen={onClosePopup}>
          <Popup status={statuses[activePlot.status]} plot={activePlot} />
        </PlotModal>
      )}
    </Box>
  );
}

Mobile.propTypes = {
  children: PropTypes.node,
  layers: PropTypes.object.isRequired,
  initialLayers: PropTypes.array.isRequired,
  hotspots: PropTypes.array.isRequired,
  plots: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  statuses: PropTypes.object.isRequired,
  statusFilter: PropTypes.object.isRequired,
  priceFilter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onLayerChange: PropTypes.func.isRequired,
  onToggleFilterModal: PropTypes.func.isRequired,
  onToggleMap: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  onHotspotClick: PropTypes.func.isRequired,
  activeLayer: PropTypes.object.isRequired,
  activePlot: PropTypes.object,
  activeFilters: PropTypes.object.isRequired,
  filteredPlots: PropTypes.array.isRequired,
  showFilters: PropTypes.bool.isRequired,
  showMap: PropTypes.bool.isRequired,
};
