import { Box, ChakraProvider, Spinner, extendTheme } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/styled-system';

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { View } from '../Organisms';
import { Default, Outline, Solid } from '../Services/';
import getProjectData from '../Services/Api/Api.js';
import { lightenDarkenColor } from '../Services/Formatters/Color';
import { hashMap } from '../Services/Hashmap/HashMap';

export default function Project() {
  const [theme, setTheme] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    const fetchProjectData = async () => {
      const projectData = await getProjectData()
        .then((response) => response.json())
        .then((projectData) => {
          setLoading(false);
          return projectData;
        })
        .catch((error) => {
          setError(true);
          console.log(error);
        });

      setApiData({
        title: projectData.name,
        description: projectData.description,
        style_settings: projectData.style_settings,
        statuses: projectData.statuses,
        filters: projectData.filters,
        plots: hashMap(projectData.plots, 'id'),
        hotspots: projectData.hotspots,
        layers: hashMap(projectData.layers, 'id'),
        initialLayers: projectData.layers,
        is_open_filter: projectData.is_open_filter,
      });

      const buttonTheme = defineStyleConfig({
        defaultProps: Default,
        variants: {
          solid: Solid,
          outline: Outline,
        },
      });

      const extended = extendTheme({
        components: {
          Button: buttonTheme,
        },
        shadows: {
          '10xl': `
            0px 2.767256498336792px 2.2138051986694336px 0px rgba(0, 0, 0, 0.0675),
            0px 6.650102138519287px 5.32008171081543px 0px rgba(0, 0, 0, 0.0969),
            0px 12.521552085876465px 10.017241477966309px 0px rgba(0, 0, 0, 0.12),
            0px 22.3363094329834px 17.869047164916992px 0px rgba(0, 0, 0, 0.1431),
            0px 41.777610778808594px 33.422088623046875px 0px rgba(0, 0, 0, 0.1725),
            0px 100px 80px 0px rgba(0, 0, 0, 0.24)`,
        },
        colors: {
          primary: {
            bg: {
              default: projectData.style_settings.primaryColor,
              transparent: 'transparent',
              lighter: lightenDarkenColor(
                projectData.style_settings.primaryColor,
                20
              ),
              lightest: lightenDarkenColor(
                projectData.style_settings.primaryColor,
                30
              ),
              darker: lightenDarkenColor(
                projectData.style_settings.primaryColor,
                -20
              ),
              darkest: lightenDarkenColor(
                projectData.style_settings.primaryColor,
                -30
              ),
            },
            border: {
              default: '#D3D3D3',
            },
            txt: projectData.style_settings.textOnPrimaryColor,
          },
          default: {
            txt: projectData.style_settings.generalTextColor,
          },
        },
        fonts: {
          heading: `${projectData.style_settings.googleFont}, sans-serif`,
          text: `${projectData.style_settings.googleFont}, sans-serif`,
        },
      });

      setTheme(extended);
    };

    fetchProjectData();
  }, []);

  return (
    <main className="p-0">
      <ChakraProvider theme={theme}>
        {loading && (
          <Box
            display="flex"
            width="100vw"
            height="100vh"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner size="xl" thickness="4px" />
          </Box>
        )}

        {error && (
          <Box p={4} color={'red'}>
            ERROR: {error}
          </Box>
        )}

        {!loading && !error && (
          <>
            <Helmet>
              <title>{apiData.title} | Heijmans</title>
              <meta name="description" content={apiData.description} />
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin
              />
              <link
                href={`https://fonts.googleapis.com/css2?family=${apiData.style_settings.googleFont}:wght@400;600&display=swap`}
                rel="stylesheet"
              />
            </Helmet>

            <div className="sr-only">
              <h1>{apiData.title}</h1>
            </div>

            <View
              layers={apiData.layers}
              initialLayers={apiData.initialLayers}
              plots={apiData.plots}
              filters={apiData.filters}
              hotspots={apiData.hotspots}
              statuses={apiData.statuses}
              isFiltersInitiallyOpen={apiData.is_open_filter}
            />
          </>
        )}
      </ChakraProvider>
    </main>
  );
}
