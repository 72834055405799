/**
 * Retrieve the key of an object that has a specific value.
 * @param {object} object The object containing the key/value pairs that we want to search.
 * @param {string} value The value of the key that we want to retrieve
 * @returns {string} The key belonging to the value in the object
 */
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

/**
 * Check if 2 arrays have identical values.
 * Taken from https://www.30secondsofcode.org/js/s/have-same-contents
 * @param {Array} a First array to compare
 * @param {Array} b Second array to compare
 * @returns {boolean} Wether the arrays are identical or not
 */
export function hasSameContents(a, b) {
  for (const v of new Set([...a, ...b]))
    if (a.filter((e) => e === v).length !== b.filter((e) => e === v).length)
      return false;
  return true;
}
