import { defineStyle } from '@chakra-ui/react';

export const Outline = defineStyle(() => {
  return {
    bg: 'primary.bg.transparent',
    color: 'default.txt',
    borderColor: 'primary.border.default',
    borderRadius: 0,
    fontWeight: 400,
    padding: '12px',
    fontSize: 'sm',

    _hover: {
      bg: 'primary.bg.default',
      borderColor: 'primary.bg.default',
      color: 'primary.txt',
    },

    _focus: {
      bg: 'primary.bg.darkest',
      borderColor: 'primary.bg.darkest',
      color: 'primary.txt',
    },

    _active: {
      bg: 'primary.bg.darkest',
      borderColor: 'primary.bg.darkest',
      color: 'primary.txt',
    },
  };
});
